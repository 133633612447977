import { render, staticRenderFns } from "./pool-info-mobile.vue?vue&type=template&id=b9070d14&scoped=true&"
import script from "./pool-info-mobile.vue?vue&type=script&lang=ts&"
export * from "./pool-info-mobile.vue?vue&type=script&lang=ts&"
import style0 from "./pool-info-mobile.vue?vue&type=style&index=0&id=b9070d14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9070d14",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VImg,VSpacer})
